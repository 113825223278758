<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">Посмотреть лекарства</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button type="primary">Сохранить</el-button>
              <el-button type="success">Сохранить и закрыть</el-button>-->
              <el-button @click="close()">Закрыть</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-card class="box-card">
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Наименование</th>
              <th scope="col">Количество необходимости</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Цефазалин</th>
              <td>30</td>
            </tr>
            <tr>
              <th scope="row">Цефазалин</th>
              <td>30</td>
            </tr>
            <tr>
              <th scope="row">Цефазалин</th>
              <td>30</td>
            </tr>
          </tbody>
        </table>
      </div>

      <el-row :gutter="25">
        <el-col :span="16">
          <el-form-item>
            <el-select v-model="form.region" placeholder="Главные Склады">
              <el-option label="Zone one" value="shanghai"></el-option>
              <el-option label="Zone two" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        num: 1
      }
    };
  }
};
</script>

